body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.login {
  margin-top: 50px;
}

.error-page {
  margin-top: 100px;
}

/* Bootstrap 5 legacy */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.navbar {
  padding: 0.5rem 1rem;
}

a { text-decoration: none; }

button {
  margin-left: 3px;
  margin-right: 3px;
}

#dashboard-title {
  margin-left: 25px;
}

.datatable-orderby {
  cursor: 'pointer';
}

.divider {
  border-top: 1px solid #ececec;
  margin-left: 20px;
  margin-right: 20px;
}

.nav-link {
  cursor: pointer;
  /* text-decoration: none; */
  color: rgba(26,26,26,.75);
}

.vertical-space {
  margin-top: 20px;
}